module.exports = {
    orange: "#FE5200",
    orange_dark: "#DE5D00",
    black: "#000000",
    darkgrey: '#403937', 
    lightgrey: '#E8E8E8',
    background1:'#EDEBE3',//headaer
    background2: "#F5EFE9", //footer
    background3: "#F1ECEC",
    lightpink: "#F0ECEC", 
    yellowcase: "#EDDBC3",
    darkgreyline: "#403937",
    lightgreyline: '#898989',
    casestudyline: '#A3A3A3',
  }
  