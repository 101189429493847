module.exports = {
    "h1-l": "100",
    "h1-lh-l": "90",
    "h1-m": "64",
    "h1-lh-m": "60",
    "h1-s": "70",
    "h1-lh-s": "66",
    "h1-ss": "60",
    "h1-lh-ss": "60",

    "h2-l": "32",
    "h2-lh-l": "50",
    "h2-m": "28",
    "h2-lh-m": "38",
    "h2-s": "36",
    "h2-lh-s": "46",
    "h2-ss": "32",
    "h2-lh-ss": "40",

    "h3-l": "18",
    "h3-lh-l": "40",

    "article-l": "26",
    "article-lh": "40",
    
    "logo-l": "20",
    "logo-s": "22",

    "p-l": "16",
    "p-lh": "30",
    
    "quoteIcon-l": "80",

    "author-l": "14",
    "author-lh-l": "20",

    "ff-header": 'orpheuspro',
    "ff-logo":'Karla-Bold',
    "ff-p": 'Libre Franklin',
    "ff-quotes": 'orpheuspro',
    "ff-quoteIcon": 'Work-Sans',
}