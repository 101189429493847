import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import colors from "../styles/vars/colors"
import bp from "../styles/vars/bp"

import { Container } from "../styles/grid"
import { Subcopy } from "../styles/typography/typography"
import ContactIcon from "../../images/svg/contact.svg";
import InstaIcon from "../../images/svg/icon_insta.svg";
import ContactIconHover from "../../images/svg/contact_hover.svg";
import InstaIconHover from "../../images/svg/icon_insta_hover.svg";

const FooterContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: ${bp["bp-mobile"]}) {
      height: 181px;
      padding: 0 36px;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
  }
`

const Contact = styled(Container)`
  text-align: right;
  display: flex;
  .nav__contact__icon{
     background-image: url(${ContactIcon});
      background-size: contain;
      background-repeat: no-repeat;
      background-size: contain;
      width: 25px;
      height: 20px;
      margin-right: 10px;
      display: inline-flex;
  }

  .nav__insta__icon{
      background-image: url(${InstaIcon});
      background-size: contain;
      background-repeat: no-repeat;
      background-size: contain;
      width: 25px;
      height: 20px;
      display: inline-flex;
  }

  .contact-email{
    margin-right: 40px;
  }

  @media (max-width: ${bp["bp-mobile"]}) {
    flex-flow: column;
    align-items: flex-start;
  }
`

const FooterCopy = styled(Subcopy)`
  display: flex;
  justify-content: center;
  align-items: center; 
  font-weight: 100;
  a{
    display: flex;
    align-items: center;
    @media (hover: hover) {
    &:hover{
      color: ${colors["orange"]};
        .nav__contact__icon{
          background-image: url(${ContactIconHover});
        }

        .nav__insta__icon{
          background-image: url(${InstaIconHover});
        }
      }
    }
  }

  @media (max-width: ${bp["bp-mobile"]}) {
        margin-bottom: 20px;
  }
`

const Footer = ({ content }) => (
  <FooterContainer bgColor={colors.background2} h={'75px'} padding>
    <FooterCopy>{content.text}</FooterCopy>
    <Contact>
      <FooterCopy className="contact-email">
        <a href="mailto:hello@cosignprojects.com">
          <span className="nav__contact__icon"></span>
          {content.contacts[0]}
        </a>
      </FooterCopy>
      <FooterCopy>
        <a href="https://www.instagram.com/cosignprojects/" target="_blank">
          <span className="nav__insta__icon"></span>
          {content.contacts[1]}
        </a>
      </FooterCopy>
    </Contact>
  </FooterContainer>
)

Footer.propTypes = {
  content: PropTypes.object,
}

Footer.defaultProps = {
  content: ``,
}

export default Footer
