import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from 'react';
import styled from "styled-components"
import colors from "../styles/vars/colors"
import { Container } from "../styles/grid"
import bp from "../styles/vars/bp"
import { NavCopy, Logo } from "../styles/typography/typography"
import ContactIcon from "../../images/svg/contact.svg";
import ContactIconHover from "../../images/svg/contact_hover.svg";
import Hamburger from "../../images/svg/hamburger.svg";
import HamburgerClose from "../../images/svg/hamburger_close.svg";
import Sticky from 'react-stickynode';


const HeaderWrapper = styled(Container)`
  .header--stick{
    .header__Container{
        height: 50px;
        transition: all 0.2s ease-out;
        .nav__logo{
          transform: scale3d(0.8, 0.8, 0.8);
          transition: transform 0.3s ease-out;
        }
    }
    .sticky-inner-wrapper {
      background: ${colors.background1};
    }
  }

  .header__mobileNav{
    border-bottom: 1px ${colors.lightgreyline} solid;
    height: 1px;
    transition: all 0.2s ease;
    overflow: hidden;

    @media (min-width: ${bp["bp-tablet"]}) {
        display: none !important
    }
      

    li{
      list-style: none;
      padding-left: 30px;
    }

    .page0{
      margin-top:20px;
      text-decoration: underline;
    }

  }

  &.--open{
    .nav__cta{
      .nav__hamburger{
          background-image: url(${HamburgerClose});
        }
      }
      .header__mobileNav{
        display: block;
        height: 300px;
        opacity: 1;
        transition: all 0.2s ease;
      }
      li:hover{
        @media (hover: hover) {
          p{
            cursor: pointer;
            color: ${colors["orange"]};
          }
        }
      }
    }
    
`

const HeaderTitle = styled(NavCopy)`
  margin-right: 30px;
  @media (max-width: ${bp["bp-tablet"]}) {
      display: none;
  }
`
const HeaderContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px ${colors.lightgreyline} solid;
  height: 70px;
  transition: all 0.2s ease;
  


  @media (max-width: ${bp["bp-tablet"]}) {
    padding: 2px 30px 0px 30px;
    height: 82px;
    border-bottom: 0;
  }

  .nav__link {
    transition: font-size 0.2s ease-out;
    cursor: pointer;
    @media (hover: hover) {
      &:hover{
        color: ${colors["orange"]};
        .nav__contact__icon{
          background-image: url(${ContactIconHover});
        }
      }
    }
  }
  .nav__logo{
      margin: 0;
      transition: transform 0.3s ease-out;
  }

  .nav__cta {
    display: flex;
    p { 
      &:first-child {
        margin-right: 15px;
      }
    }
    .nav__hamburger{
      background-image: url(${Hamburger});
      background-size: contain;
      background-repeat: no-repeat;
      background-size: contain;
      width: 25px;
      height: 20px;
      margin-right: 10px;
      display: inline-flex;
      display: none;
      @media (max-width: ${bp["bp-tablet"]}) {
        display: block;
      }
    }
  }
  .nav__contact{
    text-align: right;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    a{
        display: flex;
        align-items: center;
        span{
          cursor: pointer;
        }
    }

    .nav__contact__icon{
      background-image: url(${ContactIcon});
      background-size: contain;
      background-repeat: no-repeat;
      background-size: contain;
      width: 25px;
      height: 20px;
      margin-right: 10px;
      display: inline-flex;
    }

    @media (max-width: ${bp["bp-mobile"]}) {
      .nav__contact__text{
          display: none;
        }
    }
  }
`

const Header = ({ content, setNavID, updateStatus}) => {
  const [openNav, setOpenNav] = useState(false);

  const onClickNav = (evt) => {
    evt.preventDefault();
    setOpenNav(!openNav)
  }

  const onClickNavList = (evt, idx) => {
    evt.preventDefault();
    setNavID && setTimeout(() => setNavID(idx), 0);
    setTimeout(() => setOpenNav(false), 100);
    setNavID && setTimeout(() => setNavID(-1), 1000);
  }

  const handleStateChange = (status) => {
    if (status.status === Sticky.STATUS_FIXED) {
      updateStatus &&  updateStatus();
    }

    if (status.status === Sticky.STATUS_ORIGINAL) {
      updateStatus && updateStatus();
    } 
}

  const navList = content.navmobile.map((item, idx) => 
      <li key={`nav_${idx}`} className="nav__item" onClick={(evt) => onClickNavList(evt, idx + 1)}><NavCopy>{item}</NavCopy></li>
  );

  return (
    <HeaderWrapper className={`${openNav ? '--open' : '--close'}`} bgColor={colors.background1}>
      <Sticky onStateChange={handleStateChange} enabled={true} top={0} innerZ={10} activeClass={'header--stick'}>
        <HeaderContainer className="header__Container" padding>
          <div className="nav__cta">
            <span className="nav__hamburger" onClick={(evt) => onClickNav(evt)}></span>
            <HeaderTitle onClick={(evt) => onClickNavList(evt, 0)}><Link className="nav__link" to="/" >{content.navs[0]}</Link></HeaderTitle>
            {/* <HeaderTitle><Link className="nav__link" to="/ideas/">{content.navs[1]}</Link></HeaderTitle> */}
          </div>
          <Logo className="nav__logo">{content.title}</Logo>
          <NavCopy className="nav__contact">
            <a className="nav__link" href="mailto:hello@cosignprojects.com" >
              <span className="nav__contact__icon"></span>
              <span className="nav__contact__text">{content.contact}</span>
            </a></NavCopy>
        </HeaderContainer>
        <div className="header__mobileNav">
          <li className="page0" onClick={(evt) => onClickNavList(evt, 0)}><NavCopy>{content.navs[0]}</NavCopy></li>
          {navList}
          </div>
      </Sticky>
      </HeaderWrapper>
    )
}

Header.propTypes = {
  content: PropTypes.object,
}

Header.defaultProps = {
  content: ``,
}

export default Header
