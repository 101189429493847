/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header/header"
import Footer from "./footer/footer"


import "./layout.css"

const Layout = ({ children, setNavID, updateStatus }) => {
  
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          header {
            title,
            navs,
            navmobile,
            contact
          },
          footer {
            text,
            contacts
          },
        }
      }
    }
  `)

  return (
    <>
      <Header content={data.site.siteMetadata?.header} setNavID={setNavID} updateStatus={updateStatus}/>
      <main>{children}</main>
      <Footer content={data.site.siteMetadata?.footer} />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
