import styled, { css } from "styled-components"
import bp from "./vars/bp"
import margin from "./vars/bp"
import colors from '../styles/vars/colors'

export const Container = styled.div`
   ${props => props.center &&
		css`
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
		`
	}
	${props=> props.center_vert && 
		css`
			display: flex;
			align-items: center;
		`
	}
	${props => props.debug && 
		css`border: 1px black solid;`}

	${props => props.main &&
		css`
			border-bottom: 2px ${colors.darkgreyline} solid;
		`
	}
	${props => props.padding &&
		css`
			padding: 0px 50px;
			@media (max-width: ${bp["bp-mobile"]}) {
				padding: 0px 30px;
			}
		`
	}

	${props => props.spaceBtw && css`
		justify-content: space-between;
		`
	}
	${props => props.alignTop && css`
			align-items: flex-start;
		`
	}
	${props => props.half &&
		css`
			width: calc(( 11.5 / 24 ) * 100%);
			@media (max-width: ${bp["bp-tablet"]}) {
				flex-direction: column;
				width: 100%;
			}
		`
	}

	${props => props.full &&
		css`
			width: 100%;
		`
	}

	box-sizing: content-box;
	height: ${props => props.h && props.h};
	width: ${props => (props.w && `${(props.w / 24) * 100}%`)};
	max-height: ${margin["block-max-height"]};
	background: ${props => (props.bgColor && props.bgColor)};
	flex-direction: ${props => (props.flex_dir && props.flex_dir)};
	padding: ${props => (props.pd && props.pd)};
	margin: ${props => (props.mg && `${props.mg} auto ${props.mg} auto` )};
	margin-top: ${props => (props.mg_t && `${props.mg_t}`)};
	margin-bottom: ${props => (props.mg_b && `${props.mg_b}`)};
	
	@media (max-width: ${bp["bp-tablet"]}) {
		width: ${props => props.w_t ? `${(props.w_t / 24) * 100}%` : 'unset' };
		padding: ${props => (props.pd_m && props.pd_m)};
	}
	@media (max-width: ${bp["bp-mobile"]}) {
		width: ${props => props.w_m ? `${(props.w_m / 24) * 100}%` : 'unset' };
		padding: ${props => (props.pd_s && props.pd_s)};
	}
`

export const TitleContainer = styled(Container)`
	border-bottom: 1px ${colors.lightgreyline} solid;
    display: flex;
    align-items: center;
	height: 65px;
`

export const ContentContainer = styled(Container)`
	display: flex;
	position: relative;
	@media (max-width: ${bp["bp-mobile"]}) {
		flex-direction: column;
	}
`

export const Row = styled(ContentContainer)`
	flex-direction: row;
`

export const Column = styled(ContentContainer)`
	flex-direction: column;
`


export const Divider = styled.div`
	display: flex;
	height: 100%;
	width:1px; 
	background:  ${colors.lightgreyline};
	position: absolute;
	transform: translateX(-50%);
    left: 50%;

	@media (max-width: ${bp["bp-mobile"]}) {
		height: 1px;
		width: 100%;
		margin: 0;
	}
	
	@media (max-width: ${bp["bp-tablet"]}) {
		display: ${props => props.desktop ?'none' : 'flex'};
	}
	
	
`

