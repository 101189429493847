import styled from "styled-components"
import "./fontface.css"
import colors from "../vars/colors"
import bp from "../vars/bp"
import fonts from "../vars/fonts"


//font-size: calc(14px + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
/*
const FluidFontSize = (mobile, desktop) => {
  let size = (mobile + (desktop - mobile) * (('100vw' - bp["bp-desktop-s"]) / (bp["bp-desktop-l"] - bp["bp-desktop-s"]) ));
  console.log(size)
  return size;
}
*/

export const BODY = styled.div`
  color: ${colors["black"]};
  cursor: default;
  font-display: fallback; /* <- this can be added to each @font-face definition */
`

export const H1 = styled.h1`
  font-family: ${fonts["ff-header"]};
  font-size: calc( (${fonts["h1-m"]}px + (${fonts["h1-l"]} - ${fonts["h1-m"]}) * ((100vw - ${bp["bp-tablet"]}) / (1440 - 1024)) ));
  line-height: calc( (${fonts["h1-lh-m"]}px + (${fonts["h1-lh-l"]} - ${fonts["h1-lh-m"]}) * ((100vw - ${bp["bp-tablet"]}) / (1440 - 1024)) ));
  color: ${colors["darkgrey"]};
  text-align: left;
  font-weight: 400;
  font-display: fallback; /* <- this can be added to each @font-face definition */
  @media (max-width: ${bp["bp-tablet"]}) {
    font-size: ${fonts["h1-s"]}px;
    line-height: ${fonts["h1-lh-s"]}px;
  }
  @media (max-width: ${bp["bp-mobile-s"]}) {
    font-size: calc( (${fonts["h1-ss"]}px + (${fonts["h1-s"]} - ${fonts["h1-ss"]}) * ((100vw - ${bp["bp-mobile-ss"]}) / (414 - 370)) ));
    line-height: calc( (${fonts["h1-lh-ss"]}px + (${fonts["h1-lh-s"]} - ${fonts["h1-lh-ss"]}) * ((100vw - ${bp["bp-mobile-ss"]}) / (414 - 370)) ));
  }
`

export const H2 = styled(H1)`
  font-size: calc( (${fonts["h2-m"]}px + (${fonts["h2-l"]} - ${fonts["h2-m"]}) * ((100vw - ${bp["bp-tablet"]}) / (1440 - 1024)) ));
  line-height: calc( (${fonts["h2-lh-m"]}px + (${fonts["h2-lh-l"]} - ${fonts["h2-lh-m"]}) * ((100vw - ${bp["bp-tablet"]}) / (1440 - 1024)) ));
  p, li{
    height: 25px;
    display: flex;
    align-items: center;
    
  }
  @media (max-width: ${bp["bp-mobile"]}) {
    font-size: ${fonts["h2-s"]}px;
    line-height: ${fonts["h2-lh-s"]}px;
  }

  @media (max-width: ${bp["bp-mobile-s"]}) {
    font-size: calc( (${fonts["h2-ss"]}px + (${fonts["h2-s"]} - ${fonts["h2-ss"]}) * ((100vw - ${bp["bp-mobile-ss"]}) / (414 - 370)) ));
    line-height: calc( (${fonts["h2-lh-ss"]}px + (${fonts["h2-lh-s"]} - ${fonts["h2-lh-ss"]}) * ((100vw - ${bp["bp-mobile-ss"]}) / (414 - 370)) ));
  }
`
export const SetHeightH2 = styled(H2)`
    margin-bottom: 35px;
    height: 25px;
    display: flex;
    align-items: center;
`

export const Subcopy = styled.p`
  font-family: ${fonts["ff-p"]};
  font-size: ${fonts["p-l"]}px;
  line-height: ${fonts["p-lh"]}px;
  color: ${colors["darkgrey"]};
  text-align: left;
  margin-bottom: 0;
  cursor: default;
  font-display: fallback; /* <- this can be added to each @font-face definition */
`

export const NavCopy = styled(Subcopy)`
  font-weight: 100;
  text-transform: uppercase;
  font-size: ${fonts["h3-l"]}px;
`

export const BlockTitle = styled(Subcopy)`
    font-family: ${fonts["ff-header"]};
    font-style: italic;
    font-size: ${fonts["h3-l"]}px;
`

export const CaseStudyTitle = styled(BlockTitle)`
  border-top: 1px solid black;
  border-bottom: 1px solid black;
`
export const QuoteAuthor = styled(Subcopy)`
    color: ${colors["orange"]};
    text-align: center;
    font-size: ${fonts["author-l"]}px;
    line-height: ${fonts["author-lh-l"]}px;
    margin-bottom: 0;
`

export const Quote = styled(H2)`
  font-family: ${fonts["ff-quotes"]};
  color: ${colors["orange"]};
  text-align: center;
  margin-bottom: 20px;
`

export const Logo = styled(Quote)`
  font-family: ${fonts["ff-logo"]};
  font-weight: 700;
  font-size: calc( (${fonts["logo-s"]}px + (${fonts["logo-l"]} - ${fonts["logo-s"]}) * ((100vw - ${bp["bp-mobile-s"]}) / (1440 - 414)) ));
  color: ${colors["darkgrey"]};
`

export const QuoteIcon = styled.span`
  font-family:${fonts["ff-quoteIcon"]};
  font-size: calc( (${fonts["h1-s"]}px + (${fonts["quoteIcon-l"]} - ${fonts["h1-s"]}) * ((100vw - ${bp["bp-mobile-s"]}) / (1440 - 414)) ));
  color: ${colors["orange"]};
  display: block;
`

